<template>
  <div class="right-menu shipping-right billSection" v-loading.fullscreen.lock="settingsLoader">
    
    <subHeader pageName="SETTINGS"/>

    <tutorialsAction moduleName="SETTINGS"/>

    <div class="db-blue-section">
      <ValidationObserver ref="observer">
        <div class="db-container">
          <div class="saveMenuBtn">
            <loaderBtn v-if="isLoading"/>  
            <button form="settings-information" button="submit" @click="validate()" v-else>
              SAVE
            </button>
          </div>
          <!-- store settings form starts here -->
          <div class="billFormMainBlock mt-0">
            <form id="settings-information" @submit.prevent="validate()">
              <div class="row">
                <div class="col-md-12">
                  <div class="uploadNewUserImg storeLogoImg">
                    <div class="userDetailsImg">
                      <img 
                        :src="store.logo_src ? store.logo_src : settings.logo ? base_image_url + settings.store_id + '/storeConfiguration/' + settings.logo : '/images/logo-placeholder.jpg'"
                        alt=""
                        @error="handleImageError"
                      >
                    </div>
                    <div class="uploadImgFile">
                      <input type="file" name="storeLogo" ref="logoInput" accept="image/png,image/jpg,image/jpeg,image/webp" @change.prevent="changeAddImage('logo',$event)">
                      <button>
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path d="M20.9984 6.24971C20.9984 4.45495 19.5435 3 17.7487 3H6.24971C4.45495 3 3 4.45495 3 6.24971V17.7487C3 19.5435 4.45495 20.9984 6.24971 20.9984H11.1644L11.5202 19.5754C11.5266 19.5497 11.5333 19.5241 11.5402 19.4985H6.24971C6.04586 19.4985 5.85015 19.4637 5.66822 19.3996L11.4745 13.7138L11.558 13.6431C11.8493 13.4307 12.2588 13.4543 12.5238 13.7139L14.6028 15.7501L15.6636 14.6893L13.5732 12.6423L13.4448 12.5257C12.5666 11.7877 11.2581 11.8265 10.4251 12.6421L4.60312 18.3423C4.53629 18.157 4.49987 17.9571 4.49987 17.7487V6.24971C4.49987 5.2833 5.2833 4.49987 6.24971 4.49987H17.7487C18.7151 4.49987 19.4985 5.2833 19.4985 6.24971V11.2317C19.9774 11.0412 20.492 10.9678 20.9984 11.0115V6.24971ZM17.503 8.75161C17.503 7.50791 16.4947 6.49969 15.251 6.49969C14.0073 6.49969 12.9991 7.50791 12.9991 8.75161C12.9991 9.99531 14.0073 11.0035 15.251 11.0035C16.4947 11.0035 17.503 9.99531 17.503 8.75161ZM14.499 8.75161C14.499 8.33626 14.8357 7.99956 15.251 7.99956C15.6664 7.99956 16.0031 8.33626 16.0031 8.75161C16.0031 9.16695 15.6664 9.50366 15.251 9.50366C14.8357 9.50366 14.499 9.16695 14.499 8.75161ZM19.0984 12.6686L13.1965 18.5705C12.8524 18.9146 12.6083 19.3458 12.4903 19.8179L12.0327 21.6484C11.8336 22.4445 12.5547 23.1656 13.3508 22.9666L15.1813 22.5089C15.6534 22.3909 16.0846 22.1468 16.4287 21.8027L22.3306 15.9008C23.2231 15.0082 23.2231 13.5611 22.3306 12.6686C21.4381 11.7761 19.991 11.7761 19.0984 12.6686Z"/>
                        </svg>
                      </button>
                    </div>
                    <p class="storeLogoLabel">Store Logo</p>
                  </div>
                  <span class="text-danger" v-if="storeImageValidations.imgIsRequired">Image field is required</span>
                </div>
                <div class="col-md-6">
                  <ValidationProvider name="Store Name" rules="required" v-slot="{ errors }">
                    <div class="formContentBox">
                      <label for="storeName">Store Name <span>*</span></label>
                      <input 
                        type="text" 
                        id="storeName" 
                        name="storeName" 
                        class="form-control" 
                        v-model="settings.name" 
                        disabled
                      >
                      <span class="text-danger">{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-md-6">
                  <div class="formContentBox">
                    <label for="storeDomain">Domain</label>
                    <input 
                      type="text" 
                      id="storeDomain" 
                      name="storeDomain" 
                      class="form-control" 
                      v-if="settings.secondary_domain != null" 
                      v-model="settings.secondary_domain" 
                      disabled
                    >
                    <input 
                      type="text" 
                      id="storeDomain" 
                      name="storeDomain" 
                      class="form-control" 
                      v-else v-model="settings.domain" 
                      disabled
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="formContentBox fileUploaderBox">
                    <label>Favicon</label>
                    <div class="favFlexBox">
                      <div class="faviconField">
                        <input 
                          type="file" 
                          name="favicon" 
                          id="storeFavicon" 
                          ref="faviconInput" 
                          accept="image/png,image/jpg,image/jpeg,image/webp" 
                          @change.prevent="changeAddImage('favicon',$event)"
                        >
                        <span class="text-danger" v-if="storeImageValidations.imgIsRequired">Image field is required</span>
                      </div>
                      <div class="faviconImgOutput" v-if="store.favicon_src || settings.favicon">
                        <img 
                          ref="faviconImg" 
                          :src="store.favicon_src ? store.favicon_src : settings.favicon ? base_image_url + settings.store_id + '/storeConfiguration/' + settings.favicon : '/images/favicon.webp'"
                          alt=""
                          @error="handleImageError"
                        >
                        <div class="clearFaviconBtn">
                          <button @click.prevent="removeFaviconHandle">
                            <svg data-name="Capa 1" id="Capa_1" viewBox="0 0 20 19.84" xmlns="http://www.w3.org/2000/svg">
                              <path d="M10.17,10l3.89-3.89a.37.37,0,1,0-.53-.53L9.64,9.43,5.75,5.54a.37.37,0,1,0-.53.53L9.11,10,5.22,13.85a.37.37,0,0,0,0,.53.34.34,0,0,0,.26.11.36.36,0,0,0,.27-.11l3.89-3.89,3.89,3.89a.34.34,0,0,0,.26.11.35.35,0,0,0,.27-.11.37.37,0,0,0,0-.53Z"/>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="emailInput">
                    <div class="formContentBox">
                      <label>Established Since</label>
                      <el-input 
                        v-model="settings.established_since" 
                        placeholder="Established since"
                        size="small"
                      >
                        <template #append>
                          <el-button @click.prevent="changeFieldVisiblity('established-since')">
                            {{ settings.is_show_established_since == 1 ? 'Hide' : 'Show' }}
                          </el-button>
                        </template>
                      </el-input>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="emailInput">
                    <ValidationProvider name="address" rules="required|max:3000" v-slot="{ errors }">
                      <div class="formContentBox">
                        <label>Address <span>*</span></label>
                        <el-input 
                          v-model="settings.address" 
                          placeholder="Address"
                          size="small"
                        >
                          <template #append>
                            <el-button @click.prevent="changeFieldVisiblity('address')">
                              {{ settings.is_show_address == 1 ? 'Hide' : 'Show' }}
                            </el-button>
                          </template>
                        </el-input>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-md-6" id="store-contact">
                  <div class="emailInput">
                    <ValidationProvider name="store contact no." :rules="storeContactCountrySelected ? 'required' : '' " v-slot="{ errors }">
                      <div class="formContentBox">
                        <label for="storeContactNo">Contact No. <span>*</span></label>
                        <div class="telInputField">
                          <vue-tel-input 
                            v-model="settings.contact_no"
                            ref="storeContactInput"
                            mode="international"
                            :onlyCountries="allowedCountries"
                            :inputOptions="contactFieldOptions"
                            :dropdownOptions="contactFieldDropDownOptions"
                            :validCharactersOnly=true
                            @input="storeContactInputHandle"
                            @validate="storeContactValidateHandle"
                            @country-changed="storeContactCountryChangeHandle"
                            @focus="storeContactFieldFocusHandle"
                            @open="storeContactDropDownOpenHandle"
                            @close="storeContactDropDownCloseHandle"
                          >
                          </vue-tel-input>
                          <el-button @click.prevent="changeFieldVisiblity('contact-no')">
                            {{ settings.is_show_contact_no == 1 ? 'Hide' : 'Show' }}
                          </el-button>
                        </div>
                        <span class="text-danger" v-if="errors.length > 0">{{ errors[0] }}</span>
                        <span class="text-danger" v-else-if="!storeContactCountrySelected && storeContactFieldIsTouched">
                          Select a country from the dropdown menu or add the country code before the contact no.
                        </span>
                        <span class="text-danger" v-else-if="!storeContactIsValid && storeContactFieldIsTouched">Enter a valid contact no.</span>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="emailInput">
                    <ValidationProvider name="store email" rules="required|email" v-slot="{ errors }">
                      <div class="formContentBox">
                        <label>Email <span>*</span></label>
                        <el-input 
                          v-model="settings.email" 
                          placeholder="Email"
                          size="small"
                        >
                          <template #append>
                            <el-button @click.prevent="changeFieldVisiblity('email')">
                              {{ settings.is_show_email == 1 ? 'Hide' : 'Show' }}
                            </el-button>
                          </template>
                        </el-input>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-md-12">
                  <ValidationProvider name="store description" rules="max:500" v-slot="{ errors }">
                    <div class="formContentBox">
                      <label for="storeDescription">Store Description</label>
                      <textarea 
                        name="storeDescription" 
                        id="storeDescription"
                        placeholder="Store description"  
                        v-model="settings.description"
                      ></textarea>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-md-6">
                  <ValidationProvider name="Tax" rules="required|min_value:0|max_value:100" v-slot="{ errors }">
                    <div class="formContentBox">
                      <label for="storeTaxInput">Tax (%) <span>*</span></label>
                      <input 
                        type="number" 
                        id="storeTaxInput" 
                        name="storeTaxInput" 
                        class="form-control"
                        placeholder="Tax"  
                        v-model="settings.tax"
                      >
                      <span class="text-danger">{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-md-6">
                  <ValidationProvider name="delivery target" rules="required|min_value:1|max_value:100" v-slot="{ errors }">
                    <div class="formContentBox">
                      <label for="deliveryTarget">Delivery Target Days <span>*</span></label>
                      <input 
                        type="number" 
                        id="deliveryTarget" 
                        name="deliveryTarget" 
                        class="form-control"
                        placeholder="Delivery target days"   
                        v-model="settings.delivery_target"
                      >
                      <p class="userInfoTxt"><span>All the orders remain pending more than the set target will be considered as delayed order</span></p>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-md-6">
                  <div class="formContentBox">
                    <label for="storeCountryInput">Country <span>*</span></label>
                    <input 
                      type="text" 
                      id="storeCountryInput" 
                      name="storeCountryInput" 
                      class="form-control"
                      placeholder="Country" 
                      v-model="storeCountry"
                      disabled
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="formContentBox">
                    <label for="storeCurrencyInput">Currency <span>*</span></label>
                    <input 
                      type="text" 
                      id="storeCurrencyInput" 
                      name="storeCurrencyInput" 
                      class="form-control"
                      placeholder="Currency" 
                      v-model="settings.currency_unit" 
                      disabled
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <ValidationProvider name="weighing unit" rules="required" v-slot="{ errors }">
                    <div class="formContentBox" style="margin-bottom: 0px;">
                      <label for="storeWeighingUnit">Weighing Unit</label>
                    </div>
                    <div class="vueSelectBlock" style="margin-bottom: 20px;">
                      <Multiselect
                        id="storeWeighingUnit"
                        v-model="settings.weighing_unit"
                        :options="weighingUnits"
                        placeholder="Select a weighing unit"
                        :allow-empty="false"
                        deselect-label="Can't remove the selected item"
                      >
                        <span slot="noResult">No weighing unit found.</span>
                      </Multiselect>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="ownerInformation">
                <p>OWNER INFORMATION</p>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="formContentBox">
                    <label for="firstName">First Name</label>
                    <input 
                      type="text" 
                      id="firstName" 
                      name="firstName" 
                      class="form-control" 
                      placeholder="First name"
                      v-model="settings.first_name" 
                      @input="handleFormat('add','fname')"
                      disabled
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="formContentBox">
                    <label for="lastName">Last Name</label>
                    <input 
                      type="text" 
                      id="lastName" 
                      name="lastName" 
                      class="form-control" 
                      placeholder="Last name"
                      v-model="settings.last_name" 
                      @input="handleFormat('add','lname')"
                      disabled
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="formContentBox">
                    <label for="ownerEmail">Email</label>
                    <input 
                      type="text" 
                      id="ownerEmail" 
                      name="ownerEmail" 
                      class="form-control" 
                      placeholder="Email"
                      v-model="settings.owner_email" 
                      disabled
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="formContentBox">
                    <label for="ownerAddressL1">Contact No.</label>
                    <input 
                      type="text" 
                      id="ownerAddressL1" 
                      name="ownerAddressL1" 
                      class="form-control"
                      placeholder="Contact no."
                      v-model="storeContactData.storeOwnerContact"
                      disabled
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="formContentBox">
                    <label for="ownerAddressL1">Address Line 1</label>
                    <input 
                      type="text" 
                      id="ownerAddressL1" 
                      name="ownerAddressL1" 
                      class="form-control"
                      placeholder="Address line 1"
                      v-model="settings.address_line_1"
                      disabled
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="formContentBox">
                    <label for="ownerAddressL2">Address Line 2 </label>
                    <input 
                      type="text" 
                      id="ownerAddressL2" 
                      name="ownerAddressL2" 
                      class="form-control"
                      placeholder="Address line 2" 
                      v-model="settings.address_line_2"
                      disabled
                    >
                  </div>
                </div>
              </div>
            </form>
            
            <!--social media block starts here-->
            <div class="row">
              <div class="col-md-6">
                <div class="socialMediaField">
                  <label for="platform">Platform</label>
                  <select name="platform" id="platform" v-model="selectedPlatform">
                    <option :value="platform" v-for="(platform,index) in platformOptions" :key="index">{{ platform }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="addTableRowBtn addSocialPlatform">
                  <button type="button" @click.prevent="addSocialMediaPlatformHandle()">ADD SOCIAL PLATFORM</button>
                </div>
              </div>
              <div class="col-md-4" v-for="(smc,index) in socialMediaLinks" :key="`smc-`+index">
                <div class="addPlatformWrapper">
                  <div class="platformMainImage">
                    <div class="platformImgBox">
                      <img src="@/assets/images/socialMedia/facebook.png" alt="" v-if="smc.name == 'Facebook'">
                      <img src="@/assets/images/socialMedia/instagram.png" alt="" v-else-if="smc.name == 'Instagram'">
                      <img src="@/assets/images/socialMedia/whatsapp.png" alt="" v-else-if="smc.name == 'Whatsapp'">
                      <img src="@/assets/images/socialMedia/wechat.png" alt="" v-else-if="smc.name == 'WeChat'">
                      <img src="@/assets/images/socialMedia/x.png" alt="" v-else-if="smc.name == 'Twitter' || smc.name == 'Twitter (X)'">
                      <img src="@/assets/images/socialMedia/linkedin.png" alt="" v-else-if="smc.name == 'LinkedIn'">
                      <img src="@/assets/images/socialMedia/snapchat.png" alt="" v-else-if="smc.name == 'Snapchat'">
                      <img src="@/assets/images/socialMedia/pinterest.png" alt="" v-else-if="smc.name == 'Pinterest'">
                      <img src="@/assets/images/socialMedia/youtube.png" alt="" v-else-if="smc.name == 'YouTube'">
                      <img src="@/assets/images/socialMedia/tiktok.png" alt="" v-else-if="smc.name == 'TikTok'">
                      <img src="@/assets/images/socialMedia/tumblr.png" alt="" v-else-if="smc.name == 'Tumblr'">
                      <img src="@/assets/images/socialMedia/flickr.png" alt="" v-else-if="smc.name == 'Flickr'">
                    </div>
                    <div class="modalCloseIcon">
                      <button type="button" @click.prevent="removeSocialMediaPlatformHandle(index)">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                          <path
                            id="cross-small"
                            d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z"
                            transform="translate(-5.483 -5.483)"
                            fill="#fd4d5d"
                            stroke="#fd4d5d"
                            stroke-width="0.3"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div class="platformLinkWrapper">
                    <label for="link">Link</label>
                    <ValidationProvider 
                      :vid="`s_media_link-`+index" 
                      ref="SML_provider" 
                      name="social media link" 
                      :rules="{required:true,regex:'(^https?://)|(^http?://)'}" 
                      v-slot="{ errors }"
                    >
                      <input 
                        type="text" 
                        v-model="smc.link" 
                        name="link" 
                        id="link"
                        placeholder="Link (must start with https:// or http://)"
                        form="settings-information"
                      >
                      <span class="text-danger" v-if="errors">{{ errors[0] ? errors[0] + ' ' + '(must start with "https://" or "http://") ' :'' }} </span>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>
            <!--social media block ends here-->
          </div>
          <!-- store settings form ends here -->

          <!-- save settings block starts here -->
          <div class="saveMenuBtn">
            <loaderBtn v-if="isLoading"/>  
            <button form="settings-information" button="submit" @click="validate()" v-else>
              SAVE
            </button>
          </div>
          <!-- save settings block starts here -->
        </div>
      </ValidationObserver>
    </div>

  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import { MessageBox, Message, Loading } from "element-ui";
import Multiselect from "vue-multiselect";
import subHeader from "@/components/subHeader.vue";
import tutorialsAction from "@/components/tutorialsAction.vue";
import loaderBtn from "@/components/loaderBtn.vue";

export default {
  name:'storeSettings',
  metaInfo() {
    return {
      title: 'Settings | Store Settings | YeetCommerce',
    };
  },
  data() {
    return {
      base_image_url: process.env.VUE_APP_STORE_IMAGE_BASE_URL,
      store:{
        logo: '',
        logo_src: '',
        favicon: '',
        favicon_src: '',
      },
      tempStoreSettingsData: null,
      storeContactData: {
        storeOwnerContact: '',
        storeContact: '',
      },
      tempStoreContactData:{
        storeOwnerContact: '',
        storeContact: '',
      },
      socialMediaLinks: [],
      isLoading: false,
      platformOptions: [
        'Facebook',
        'Instagram',
        'Whatsapp',
        'WeChat',
        'Twitter (X)',
        'LinkedIn',
        'Snapchat',
        'Pinterest',
        'YouTube',
        'TikTok',
        'Tumblr',
        'Flickr',
      ],
      selectedPlatform: null,
      weighingUnits:[
        'Kg','lb'
      ],
      storeCountry: {},
      countriesList: [],
      allowedCountries: [],
      storeImageValidations:{
        imgIsRequired: false,
        imgInvalidFormat: false,
        isNotImg: false,
      },
      //store contact validation properties starts here
      contactFieldOptions:{
        placeholder: "Contact no.",
        showDialCode: true,
        type: 'tel',
        maxlength: 25,
      },
      contactFieldDropDownOptions:{
        showSearchBox: true,
        showDialCodeInSelection: false,
        showDialCodeInList: true,
        showFlags: true,
        placeholder: "Select a Country"
      },
      storeContactIsValid: false,
      storeContactCountrySelected: false,
      storeContactFieldIsTouched: false,
      storeContactFieldIsTouchedOnce: false,
      storeContactSelectContactObj: {},
      storeContactCountryObjBeforeChange: {},
      storeContactCountryObj: {},
      //store contact validation properties ends here
    }
  },
  components:{
    subHeader,
    Multiselect,
    tutorialsAction,
    loaderBtn
  },
  computed:{
    ...mapGetters({
      settings:'settings_module/settings',
      settingsLoader:'settings_module/isPending',
      storeAllCountries: 'countries_module/allCountries'
    }),
  },
  watch:{
    'settings':{
      handler:function(value){

        if(value.social_media != null){

          let socialMedia = JSON.parse(value.social_media);

          let arr = [];

          for(let i=0; i < socialMedia.length ; i++){
            
            let index = this.platformOptions.findIndex(x=> x == socialMedia[i].name);

            if(index !== -1){

              let obj = {
                link:socialMedia[i].link,
                name:this.platformOptions[index],
              }

              arr.push(obj);

            }
            
          }

          this.socialMediaLinks = arr;

        }

        if(value.country){

          this.storeCountry = value.country.name;

        }

        this.storeContactData.storeOwnerContact = value.mobile_no;
        this.storeContactData.storeContact = value.contact_no;

        this.tempStoreContactData = _.cloneDeep(this.storeContactData);

      },
      deep:true
    },
    storeAllCountries:{
      handler:function(value){

        this.countriesList = value;
        
        value.forEach((country)=>{

          this.allowedCountries.push(country.iso2);

        });

      },deep:true
    },
  },
  methods:{
    handleFormat(form,field){
        
      if(form == 'add' && field == 'fname'){

        let name = this.settings.first_name;
        name = name.replace(/[^a-zA-Z ]/g, '');
        name = name.replace(/(\W)\W*/g, '$1');

        this.settings.first_name = name;

      }else if(form == 'add' && field == 'lname'){

        let lname = this.settings.last_name;
        lname = lname.replace(/[^a-zA-Z ]/g, '');
        lname = lname.replace(/(\W)\W*/g, '$1');

        this.settings.last_name = lname;

      }

    },
    resetStoreImageValidations(){

      this.storeImageValidations = {
        imgIsRequired:false,
        imgInvalidFormat:false,
        isNotImg:false,
      }

    },
    async changeAddImage(type,e){

      if(type == "logo"){

        this.storeImageValidations = {
          imgIsRequired:false,
          imgInvalidFormat:false,
          isNotImg:false,
        }

        if(e.target.files.length > 0){

          const file = e.target.files[0];

          if(file.name.length > 50){

            this.$notify({
              type: "error",
              title: "Alert",
              message: "Uploaded file name must not exceed 50 characters.",
            }); 

            this.storeImageValidations.imgIsRequired = false;

            this.$refs.logoInput.value = ''

            return

          }

          if(file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/webp' || file.type == 'image/png'){
            
            this.store.logo = file;
            this.store.logo_src = URL.createObjectURL(file);

            this.storeImageValidations.imgIsRequired = false;

          }else if(file.type.includes('image/')){

            let uploadedFileType = file.type.slice(6);
            
            this.$notify({
              type: "error",
              title: "Alert",
              message: `${uploadedFileType.toUpperCase()} is not a valid file type.`,
            });
            
            this.$refs.logoInput.value = ''

          }else{

            this.$notify({
              type: "error",
              title: "Alert",
              message: "Uploaded file must be an image.",
            }); 

            this.$refs.logoInput.value = ''

          }

        }

      }else if(type == "favicon"){

        this.storeImageValidations = {
          imgIsRequired:false,
          imgInvalidFormat:false,
          isNotImg:false,
        }

        if(e.target.files.length > 0){

          const file = e.target.files[0];

          if(file.name.length > 50){

            this.$notify({
              type: "error",
              title: "Alert",
              message: "Uploaded file name must not exceed 50 characters.",
            }); 

            this.$refs.faviconInput.value = ''

            return

          }

          if(file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/webp' || file.type == 'image/png'){
            
            this.store.favicon = file;
            this.store.favicon_src = URL.createObjectURL(file);

            this.storeImageValidations.imgIsRequired = false;

          }else if(file.type.includes('image/')){
            
            let uploadedFileType = file.type.slice(6);
            
            this.$notify({
              type: "error",
              title: "Alert",
              message: `${uploadedFileType.toUpperCase()} is not a valid file type.`,
            });

            this.$refs.faviconInput.value = ''

          }else{

            this.$notify({
              type: "error",
              title: "Alert",
              message: "Uploaded file must be an image.",
            }); 

            this.$refs.faviconInput.value = ''

          }

        }
        
      }
      
    },
    async removeFaviconHandle(){
      
      if(this.store.favicon && this.store.favicon_src){

        this.store.favicon = '';
        this.store.favicon_src = '';

        this.$refs.faviconInput.value = '';

        this.$refs.faviconImg.src = '';

        this.$notify({
          type: 'success',
          title: 'Success',
          message: "Favicon shas been successfully removed.",
        });

      }else{
        
        let res = await this.$axios.post('store/favicon/remove');
        if(res.data.status_code == "10281"){
          
          this.$notify({
            type: 'success',
            title: 'Success',
            message: res.data.message,
          });

          this.$store.dispatch('settings_module/fetchStoreSetting');

          this.$store.commit('gallery_module/CLEAR_MODULE');
          
        }

      }

    },
    addSocialMediaPlatformHandle(){

      if(this.selectedPlatform){
        
        let foundLink = this.socialMediaLinks.find(link => link.name == this.selectedPlatform)

        if(foundLink){

          this.$notify({
            type: 'warning',
            title: 'Alert',
            message: "One social media platform can only have one link.",
          });

          this.selectedPlatform = null

          return

        }

        let obj = {
          name: this.selectedPlatform,
          link:'',
        }

        this.socialMediaLinks.push(obj);

        this.selectedPlatform = null

      }else{

        this.$notify({
          type: 'warning',
          title: 'Alert',
          message: "Social media platform must be selected.",
        });

      }

    },
    removeSocialMediaPlatformHandle(index){

      MessageBox.confirm(
        'Are you sure you want to remove the social media link?',
        'Warning',
        {
          type: 'warning',
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel',
        }
      ).then(async ()=>{

        this.socialMediaLinks.splice(index,1)

      }).catch((error) => {})

    },
    async validate(){

      this.$refs.observer.validate().then(success => {

        if(!success){

          const errors = Object.entries(this.$refs.observer.errors)
          .map(([key, value]) => ({ key, value })).filter(error => error["value"].length);

          this.$refs.observer.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth",  block: "center" });

          if(typeof this.storeContactSelectContactObj.valid == 'undefined'){

            this.storeContactIsValid = false
            this.storeContactFieldIsTouched = true

            document.querySelector("#store-contact").scrollIntoView({ behavior: 'smooth',block: "center" })

          }

        }else{

          if(typeof this.storeContactSelectContactObj.valid == 'undefined'){

            this.storeContactIsValid = false
            this.storeContactFieldIsTouched = true

          }

          if(this.storeContactIsValid && this.storeContactCountrySelected){

            this.saveStoreSettings();

          }else{

            if(!this.storeContactIsValid || !this.storeContactCountrySelected){

              document.querySelector("#store-contact").scrollIntoView({ behavior: 'smooth',block: "center" })

            }

          }

        }

      });

    },

    changeFieldVisiblity(field){

      if(field == 'established-since'){

        if(this.settings.is_show_established_since == 1){

          this.settings.is_show_established_since = 0

        }else if(this.settings.is_show_established_since == 0){

          this.settings.is_show_established_since = 1

        }

      }else if(field == 'address'){

        if(this.settings.is_show_address == 1){

          this.settings.is_show_address = 0

        }else if(this.settings.is_show_address == 0){

          this.settings.is_show_address = 1

        }

      }else if(field == 'contact-no'){

        if(this.settings.is_show_contact_no == 1){

          this.settings.is_show_contact_no = 0

        }else if(this.settings.is_show_contact_no == 0){

          this.settings.is_show_contact_no = 1

        }

      }else if(field == 'email'){

        if(this.settings.is_show_email == 1){

          this.settings.is_show_email = 0

        }else if(this.settings.is_show_email == 0){

          this.settings.is_show_email = 1

        }

      }

    },

    //store contact field validations starts here
    storeContactInputHandle(number,numberObj){

      if(typeof numberObj.country == 'undefined'){
        
        this.storeContactCountrySelected = false

      }else if(typeof numberObj.countryCode != 'undefined' && numberObj.countryCode == "--"){
        
        this.storeContactCountrySelected = false

      }else if(typeof numberObj.country != 'undefined' && 
      typeof numberObj.valid == 'undefined' &&
      numberObj.formatted.length == numberObj.country.dialCode + 1){
        
        this.storeContactCountrySelected = false

      }else{

        this.storeContactCountrySelected = true
        
      }

      this.storeContactSelectContactObj = numberObj
    },
    storeContactValidateHandle(contactObj){

      if(typeof contactObj.valid != 'undefined'){

        this.storeContactIsValid = contactObj.valid

      }

      if(!this.storeContactFieldIsTouchedOnce){

        this.storeContactData.storeContact = this.tempStoreContactData.storeContact;

        this.$refs.storeContactInput.phone = this.tempStoreContactData.storeContact;

      }

    },
    storeContactCountryChangeHandle(countryObj){

      this.storeContactCountryObj = countryObj

    },
    storeContactDropDownOpenHandle(){

      this.storeContactCountryObjBeforeChange = this.storeContactSelectContactObj

    },
    storeContactDropDownCloseHandle(){

      if((this.settings.contact_no).replace(/\s/g,'').length > this.storeContactCountryObj.dialCode.length + 1){
        
        if((this.storeContactCountryObjBeforeChange.country != this.storeContactCountryObj.country)){

          this.settings.contact_no = "+" + this.storeContactCountryObj.dialCode 

        }

      }

      this.storeContactCountrySelected = true

    },
    storeContactFieldFocusHandle(){

      this.storeContactFieldIsTouched = true
      
      this.storeContactFieldIsTouchedOnce = true

    },
    //store contact field validations ends here

    async saveStoreSettings(){

      let socialMedia = [];

      this.socialMediaLinks.forEach(elem =>{

        let obj = {
          link: elem.link,
          name: elem.name
        }

        socialMedia.push(obj)

      });

      let formData = new FormData();
 
      formData.append('id',this.settings.id);
      formData.append('email',this.settings.email ? this.settings.email : null);
      formData.append('description',this.settings.description ? this.settings.description : null);
      formData.append('contact_no',this.storeContactData.storeContact ? this.storeContactData.storeContact.replace(/\s/g,'') : null);
      formData.append('address',this.settings.address ? this.settings.address : null);
      formData.append('established_since',this.settings.established_since ? this.settings.established_since : null);
      formData.append('social_media',JSON.stringify(socialMedia));
      formData.append('tax',this.settings.tax ? this.settings.tax : 0);
      formData.append('delivery_target',this.settings.delivery_target);
      formData.append('weighing_unit',this.settings.weighing_unit);

      formData.append('first_name',this.settings.first_name );
      formData.append('last_name',this.settings.last_name);
      formData.append('mobile_no',this.storeContactData.storeOwnerContact.replace(/\s/g,''));
      formData.append('address_line_1',this.settings.address_line_1);

      formData.append('is_show_established_since',this.settings.is_show_established_since);
      formData.append('is_show_email',this.settings.is_show_email);
      formData.append('is_show_contact_no',this.settings.is_show_contact_no);
      formData.append('is_show_address',this.settings.is_show_address);

      if(this.settings.address_line_2){

        formData.append('address_line_2',this.settings.address_line_2);

      }

      if(typeof(this.store.logo) == 'object'){

        formData.append('logo',this.store.logo);

      }

      if(typeof(this.store.favicon) == 'object'){

        formData.append('favicon',this.store.favicon);

      }

      let loader = Loading.service({
        text: "The store settings is being saved. Please wait!",
        fullscreen: true,
      });

      this.isLoading = true;
      try{
        let res = await this.$axios.post('updateConfigure',formData);
        if(res.data.status_code == "1028"){

          this.$notify({
            type: 'success',
            title: 'Success',
            message: res.data.message,
          })
          
          this.$refs.faviconInput.value = ''

          this.store.favicon = ''
          this.store.favicon_src = ''

          this.$store.dispatch('settings_module/fetchStoreSetting');

          this.$store.commit('gallery_module/CLEAR_MODULE');

        } 
      }catch(error){

        if(error.response){

          if(error.response.data.error.favicon){

            this.$message({
              type: 'error',
              showClose: true,
              message: "We encountered difficulties processing the favicon image.",
            });

          }else if(error.response.data.error.logo){

            this.$message({
              type: 'error',
              showClose: true,
              message: "We encountered difficulties processing the logo image.",
            });

          }else if(error.response.data.error.includes('storage limit has been reached')){

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.error,
            });

          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.message,
            });

          }

        }else{

          this.$message({
            type: 'error',
            showClose: true,
            message: error.message,
          });

        }

      }finally{

        loader.close();

        this.isLoading = false
        
      }
      
    },

    //image loading error handling
    handleImageError(e){

      e.target.src = '/images/image_not_found.png'

    },


    //fetch module data if needed
    fetchModuleData(){
      
      if(!this.settingsLoader){

        if(this.settings.social_media != null){

          let socialMedia = JSON.parse(this.settings.social_media);

          let arr = [];

          for(let i=0; i<socialMedia.length ;i++){

            let index = this.platformOptions.findIndex(x=> x == socialMedia[i].name);

            if(index !== -1){

              let obj = {
                link:socialMedia[i].link,
                name:this.platformOptions[index],
              }

              arr.push(obj);

            }

          } 

          this.socialMediaLinks = arr;

        }
          
        this.storeCountry = this.settings.country.name;

        this.storeContactData.storeOwnerContact = this.settings.mobile_no;
        this.storeContactData.storeContact = this.settings.contact_no;

        this.tempStoreContactData = _.cloneDeep(this.storeContactData);

      }

      if(!this.storeAllCountries || this.storeAllCountries.length == 0){

        this.$store.dispatch('countries_module/fetchAllCountries')

      }else{

        this.countriesList = this.storeAllCountries;

        this.countriesList.forEach((country)=>{

          this.allowedCountries.push(country.iso2);
          
        });

      }

    },

  },
  mounted(){

    //fetching module data
    this.fetchModuleData();

  },
  beforeMount(){

  },

}
</script>

<style scoped>
  .formContentBox .multiselect__input{
    display: none !important;
  }
  .telInputField{
    position: relative;
  }
  .telInputField .vue-tel-input{
    height: 52px;
  }
  .telInputField button{
    position: absolute;
    top: 0;
    right: 0;
    height: 52px;
    border: 1px solid #DCDFE6;
    background-color: #F5F7FA;
    color: #909399;
  }
</style>